import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Navigation from "sections/Navigation"
import Footer from "sections/Footer-classic"

import Privacy from '../../../mySections/BlogContent/index'
import PrivacyContent from '../../../mySections/PrivacyData/zh/privacyZ_data_todo'

import { GlobalStyle } from "sections/app.style"
import theme from "theme/classic/themeStyles"

const appName_zh = "万能清单"

const Privacy_Todo_Zh = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="To do Anything Privacy" />
      <Navigation pageName="404" />
      <Privacy
        title={appName_zh + "隐私申明"}
        content={PrivacyContent}
      />
      <Footer />
    </Layout>
  </ThemeProvider>
)
export default Privacy_Todo_Zh